<template>
  <div>
    <div class="schedules-form-view">
      <app-header
        icon="clipboard"
        title="Novo Atendimento"
        goes-back
      ></app-header>

      <section>
        <div class="card">
          <div class="card-content">
            <b-loading
              :active.sync="isLoading"
              :is-full-page="false"
            ></b-loading>

            <form @submit.prevent="onSubmit">
              <div class="row">
                <div
                  class="col-11 col-md-11 mx-sm-auto col-lg-6 mx-lg-0 mb-lg-0 mb-sm-3"
                >
                  <app-autocomplete
                    label="Cliente"
                    placeholder="Digite o Nome ou o CPF"
                    class="input-custom"
                    v-model="schedule.patient_id"
                    :getter="patientsGetter"
                    :setter="patientSetter"
                    :errors="errors.patienonSubmitt_id"
                    field="name"
                    is-required
                  ></app-autocomplete>

                  <b-button
                    id="new-patient-button"
                    class="button-rounded button-primary mt-lg-0 mt-sm-2 mb-lg-2 mb-sm-3"
                    @click.prevent="newPatient"
                  >
                    Novo cliente
                    <b-icon icon="plus"></b-icon>
                  </b-button>
                  <b-tooltip
                    label="Cadastrar Cliente"
                    target="new-patient-button"
                  />
                </div>

                <div
                  class="col-11 col-md-11 mx-sm-auto col-lg-3 mx-lg-0 mb-lg-0 mb-sm-3"
                >
                  <b-field
                    label="Data e Hora"
                    class="required"
                    :type="errors && errors.start ? 'is-danger' : null"
                    :message="errors.start"
                  >
                    <b-datetimepicker
                      trap-focus
                      locale="pt-BR"
                      :editable="true"
                      horizontal-time-picker
                      v-model="schedule.date"
                      :min-datetime="minDate"
                      placeholder="Selecionar"
                      :date-formatter="bDateTimeFormatter"
                    >
                    </b-datetimepicker>
                  </b-field>
                </div>
              </div>

              <div class="row mt-2">
                <div
                  class="col-11 col-md-11 mx-sm-auto col-lg-3 mx-lg-0 mb-lg-0 mb-sm-3"
                >
                  <b-field label="Tipo do atendimento">
                    <b-select
                      v-model="selectedAppointment"
                      class="select-custom"
                      placeholder="Selecione o tipo"
                      expanded
                    >
                      <option
                        v-for="appointment of appointments"
                        :key="appointment.id"
                        :value="appointment"
                      >
                        {{ appointment.name }}
                      </option>
                    </b-select>
                  </b-field>
                </div>

                <div
                  class="col-11 col-md-11 mx-sm-auto col-lg-3 mx-lg-0 mb-lg-0 mb-sm-3"
                >
                  <b-field label="Valor">
                    <CurrencyInput v-model="value" :options="currencyOptions" />
                  </b-field>
                </div>

                <div
                  class="col-11 col-md-11 mx-sm-auto col-lg-3 mx-lg-0 mb-lg-0 mb-sm-3"
                >
                  <b-field label="Especialidades">
                    <b-select
                      v-model="selectedSpecialty"
                      class="select-custom"
                      placeholder="Selecione a especialidade"
                      expanded
                    >
                      <option
                        v-for="specialty of specialties"
                        :key="specialty.id"
                        :value="specialty"
                      >
                        {{ specialty.name }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
                <!-- <div
                  class="col-4"
                  v-if="selectedAppointment && selectedAppointment.type !== 2"
                >
                </div> -->

                <div
                  class="col-11 col-md-11 mx-sm-auto col-lg-3 mx-lg-0 mb-lg-0 mb-sm-3"
                >
                  <b-field label="Forma de pagamento">
                    <b-select
                      v-model="type2"
                      class="select-custom"
                      placeholder="Selecionar..."
                      expanded
                      disabled
                    >
                      <option :value="0">Pix</option>
                    </b-select>
                  </b-field>
                </div>
              </div>

              <div class="row mt-5">
                <div class="col-12 has-text-right">
                  <div class="col-11 col-lg-2 mx-auto mx-lg-0 float-lg-right">
                    <b-button
                      type="submit"
                      class="btn button-rounded remove-focus btn-success border-0 px-3 btn-secondary btn-block"
                      :disabled="isLoading || !canSave"
                    >
                      Continuar
                    </b-button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
    <!-- 
    <div class="schedule-view">
      <section>
        <div class="card">
          <div class="card-content">
            <b-loading :active.sync="isLoadingCalendar" :is-full-page="false"></b-loading>
            <div class="subtitle">
              <div class="subtitle__item">
                <div class="color" :style="{ backgroundColor: typeColors[0] }"></div>
                <p>Encerrada</p>
              </div>
              <div class="subtitle__item">
                <div class="color" :style="{ backgroundColor: typeColors[1] }"></div>
                <p>A realizar</p>
              </div>
            </div>

            <full-calendar
              ref="fullCalendar"
              v-if="!isLoadingCalendar && !isLoading"
              :options="calendarOptions"
            ></full-calendar>
            
          </div>
        </div>
      </section>
    </div> -->
  </div>
</template>

<script>
import AppAutocomplete from '@/components/inputs/Autocomplete.vue';
import CurrencyInput from '@/components/inputs/CurrencyInput.vue';
import BuefyMixin from '@/mixins/BuefyMixin';
import DoctorService from '@/services/doctor.service';
// import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import PatientFormModal from '@/modals/PatientFormModal.vue';
import PatientsService from '@/services/patients.service';
import router from '@/router';
import SchedulesService from '@/services/schedules.service';
import TimeSchedule from '@/modals/TimeSchedule.vue';
// import ScheduleStandartTimeSelector from '@/components/ScheduleStandartTimeSelector';
import { mapGetters } from 'vuex';
import * as moment from 'moment';

const TYPE_COLORS = ['#182734', '#8c9296', '#50bbcf'];

let currentDate = '';

export default {
  filters: {
    time(value) {
      const [hour, min] = value.split(':');
      return `${hour}:${min}`;
    },
  },
  mixins: [BuefyMixin],
  components: {
    AppAutocomplete,
    CurrencyInput,
    // 'full-calendar': FullCalendar,
  },
  computed: {
    ...mapGetters(['this.doctorId']),
    canSave() {
      if (
        this.schedule.patient_id &&
        this.schedule.date &&
        this.value &&
        this.selectedAppointment?.id &&
        this.selectedSpecialty?.id
      )
        return true;

      return false;
    },

    isEmpty() {
      return Object.keys(this.days).reduce((a, b) => a + b.length, 0) === 0;
    },
  },
  data: () => ({
    selectedTime: Number,
    // doctorId: '',
    page: 1,
    schedulesAvailables: [],
    type2: 0,
    currencyOptions: {
      currency: 'BRL',
      locale: 'pt-BR',
      valueRange: {
        min: 10,
      },
    },
    days: {},
    weekdays: moment.weekdays(),
    calendarOptions: {
      plugins: [dayGridPlugin, interactionPlugin],
      initialView: 'dayGridMonth',
      locale: 'pt-BR',

      buttonText: {
        today: 'Hoje',
        month: 'Mensal',
        day: 'Diário',
      },

      headerToolbar: {
        left: 'title',
        center: '',
        right: 'dayGridDay,dayGridMonth,prev,next',
      },
      eventClick: ({ event }) => router.push(`schedules/${event.id}`),
    },
    events: [],
    isLoadingCalendar: false,
    date: currentDate,
    typeColors: TYPE_COLORS,

    type: 1,
    errors: {},
    schedule: {
      procedure_id: '',
      end: '',
      patient_id: '',
      start: '',
      appointment_id: '',
      forma_pagamento_id: '',
      tipo_atendimento_id: '',
      tatendimento_id: '',
    },
    procedures: [],
    isLoading: false,
    appointments: [],
    specialties: [],
    selectedSpecialty: null,
    selectedAppointment: null,
    value: 0,
    value_fee: 0,
    minDate: new Date(),
    // end: '',
    // patient_id: '',
    // start: '',
    // generate_checkout_url: '',
    // appointment_id: '',
    // procedure_id: '',
    // forma_pagamento_id: '',
    // tipo_atendimento_id: '',
    // tatendimento_id: '',
  }),
  watch: {
    type2() {},
  },
  methods: {
    formatDate(date_start, date_end) {
      let date_result = '';
      date_result = date_start ? moment(date_start).format('DD/MM/YYYY') : '';
      date_result += date_start && date_end ? ' - ' : '';
      date_result += date_end ? moment(date_end).format('DD/MM/YYYY') : '';
      return date_result;
    },

    loadTimes() {
      this.isLoading = true;

      SchedulesService.getAvailabilities()
        .then((times) => (this.days = times))
        .finally(() => setTimeout(() => (this.isLoading = false), 300));
    },

    newTime() {
      this.$buefy.modal.open({
        parent: this,
        component: TimeSchedule,
        hasModalCard: true,
        trapFocus: true,
        events: {
          close: (data) => data && this.loadTimes(),
        },
      });
    },

    deleteTime({ weekday, id }) {
      SchedulesService.deleteAvailability(id).then(() => {
        this.days[weekday] = this.days[weekday].filter(
          (time) => time.id !== id
        );
        this.$buefy.snackbar.open('Horário excluído com sucesso!');
      });
    },

    onClearClick(weekday) {
      this.$buefy.dialog.confirm({
        message: `Deseja realmente excluir todos os horários de ${this.weekdays[weekday]}?`,
        onConfirm: () => this.clearWeekday(weekday),
      });
    },

    clearWeekday(weekday) {
      const promises = this.days[weekday].map((scd) =>
        SchedulesService.deleteAvailability(scd.id)
      );

      Promise.all(promises).then(() => {
        this.days[weekday] = {};
        this.$buefy.snackbar.open('Horários excluídos com sucesso!');
      });
    },

    getTimeClass({ appointment_types }) {
      return appointment_types.map(({ id }) => `apt-${id}`);
    },

    patientsGetter: PatientsService.search,

    patientSetter: PatientsService.getId,

    save() {
      this.isLoading = true;
      this.errors = {};

      const { patient_id, date } = this.schedule;
      const format = 'YYYY-MM-DD HH:mm:ss';
      const start = date ? moment(date).format(format) : '';
      const end = date
        ? moment(date).add(this.selectedTime, 'minute').format(format)
        : '';

      const data = {
        start: start,
        end: end,
        patient_id: patient_id,
        doctor_id: this.doctorId,
        appointment_type_id: this.selectedAppointment.id,
        specialty_id: this.selectedSpecialty.id,
        value: this.value,
        // free_appointment: this.value_fee,
        forma_pagamento_id: 1,
        payment_type: 1,
        tipo_atendimento_id: this.selectedAppointment.type,
        tatendimento_id: this.tatendimento_id,
        use_discount: false,
        fit: 0,
      };

      this.isLoading = false;

      SchedulesService.store(data)
        .then(() => {
          this.$buefy.snackbar.open('Atendimento agendado com sucesso!');
          this.$router.go(-1);
        })
        .catch(({ response }) => {
          const { status, data } = response;

          let message = 'Erro inesperado, tente novamente ou vale com um administrador do sistema';

          if(status == 400) message = 'Verifique os campos!'

          this.customError(
            data,
            message
          );
        })
        .finally(() => (this.isLoading = false));
    },

    newPatient() {
      this.$buefy.modal.open({
        parent: this,
        component: PatientFormModal,
        hasModalCard: true,
        trapFocus: true,
        width: '800',
      });
    },

    loadDoctor() {
      DoctorService.getId(this.doctorId).then(({ data }) => {
        this.doctor = data;

        const appointments = data.appointment_types;

        const chat = appointments.filter(
          (appointment) => appointment.id == 1
        )[0];

        if (chat)
          this.appointments.push({
            id: chat.id,
            name: 'Chat (Whatsapp)',
            value: chat.value,
            value_fee: chat.value_fee,
            type: chat.id,
            time: chat.time,
          });

        const call = appointments.filter(
          (appointment) => appointment.id == 2
        )[0];

        if (call)
          this.appointments.push({
            id: call.id,
            name: 'Ligação',
            value: call.value,
            value_fee: call.value_fee,
            type: call.id,
            time: call.time,
          });

        const video = appointments.filter(
          (appointment) => appointment.id == 3
        )[0];

        if (video)
          this.appointments.push({
            id: video.id,
            name: 'Vídeo Chamada (Whatsapp)',
            value: video.value,
            value_fee: video.value_fee,
            type: video.id,
            time: video.time,
          });

        this.selectedAppointment = this.appointments[0];
        this.selectedTime = this.selectedAppointment.time;

        this.value = this.selectedAppointment.value;

        this.specialties = data.specialties;
      });
    },

    onSubmit() {
      this.$buefy.dialog.confirm({
        message:
          'Será enviado o link para o cliente realizar o pagamento, deseja continuar?',
        confirmText: 'Sim',
        cancelText: 'Não',
        type: 'is-secondary',
        onConfirm: () => this.save(),
      });
    },

    loadSchedule() {
      this.isLoadingCalendar = true;

      SchedulesService.getScheduleList(this.doctorId)
        .then(({ data }) => {
          data.map((schedule) => {
            const afterScheduleStart = moment().isAfter(moment(schedule.start));

            if (!schedule.canceled_at) {
              this.events.push({
                id: schedule.id,
                title: this.getEventName(schedule),
                start: moment(schedule.start).toDate(),
                end: moment(schedule.end).toDate(),
                allDay: true,
                textColor: afterScheduleStart ? 'white' : 'black',
                backgroundColor: TYPE_COLORS[afterScheduleStart ? 0 : 1],
              });
            }
          });
          this.calendarOptions.events = this.events;
        })
        .finally(() => {
          this.isLoadingCalendar = false;
          this.$emit('calendar_loaded', true);
        });
    },

    getEventName(schedule) {
      const { start, end, patient_name } = schedule;

      return `${moment(start).format('HH:mm')} - ${moment(end).format(
        'HH:mm'
      )} / ${patient_name}`;
    },
  },
  mounted() {
    this.doctorId = this.$store.getters.doctorId;
    this.isLoading = true;
    this.tatendimento_id = 2;
    this.loadDoctor();
    this.loadTimes();
    this.loadSchedule();
  },
};
</script>

<style lang="scss">
.subtitle {
  margin-bottom: 8px !important;

  &__item {
    display: flex;
    align-items: center;

    .color {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      margin-right: 8px;
    }

    p {
      font-size: 1rem;
    }
  }
}

.fc-toolbar-title {
  text-transform: capitalize;
}
</style>
