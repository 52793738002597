<template>
  <div class="patient-form-modal">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Cadastrar Cliente</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <form @submit.prevent="onSubmit">
          <div class="row">
            <div class="col-4">
              <app-input
                label="CPF"
                v-model="patient.cpf"
                :errors="errors.cpf"
                :mask="MASKS.cpf"
                is-required
              ></app-input>
            </div>
            <div class="col-4">
              <app-input
                label="Nome"
                v-model="patient.name"
                :errors="errors.name"
                is-required
              >
              </app-input>
            </div>
            <div class="col-4">
              <app-input
                label="E-mail"
                v-model="patient.email"
                type="email"
                autocomplete="email"
                :errors="errors.email"
                is-required
              ></app-input>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-4">
              <b-field class="required" label="Data de nascimento">
                <app-input
                  class="input-custom"
                  v-model.trim="patient.birthday"
                  placeholder="Digite a data."
                  v-mask="'##/##/####'"
                  is-required
                ></app-input>
              </b-field>
            </div>

            <div class="col-4">
              <b-field class="required" label="Gênero">
                <b-select
                  class="select-custom"
                  v-model="patient.gender"
                  placeholder="Selecionar"
                  expanded
                >
                  <option
                    v-for="gender in genders"
                    :value="gender.char"
                    :key="gender.char"
                  >
                    {{ gender.name }}
                  </option>
                </b-select>
              </b-field>
            </div>

            <div class="col-4">
              <app-input
                label="Telefone"
                v-model="patient.phone"
                type="tel"
                :mask="MASKS.phone"
                :errors="errors.phone"
                is-required
              ></app-input>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-3">
              <app-input
                label="CEP"
                @input="getCepInfo"
                v-model="patient.cep"
                type="text"
                :mask="MASKS.cep"
                :errors="errors.cep"
                is-required
              ></app-input>
            </div>
            <div class="col-6">
              <app-input
                label="Rua"
                v-model="patient.street"
                type="text"
                :errors="errors.street"
                is-required
              ></app-input>
            </div>
            <div class="col-3">
              <app-input
                label="Número"
                v-model="patient.number"
                type="tel"
                :errors="errors.number"
                is-required
              ></app-input>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-4">
              <app-input
                label="Complemento"
                v-model="patient.complement"
                type="text"
                :errors="errors.complement"
              ></app-input>
            </div>
            <div class="col-4">
              <app-input
                label="Bairro"
                v-model="patient.neighborhood"
                type="tel"
                :errors="errors.neighborhood"
                is-required
              ></app-input>
            </div>
            <div class="col-4">
              <app-autocomplete
                label="Cidade"
                ref="citySelector"
                v-model="patient.city_id"
                :value="patient.city_id"
                :getter="citiesGetter"
                :setter="citySetter"
                :errors="errors.city_id"
                field="name"
                is-required
              ></app-autocomplete>
            </div>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot is-right">
        <b-button @click="$emit('close')">Fechar</b-button>
        <b-button
          class="button-rounded remove-focus btn-success border-0 px-3"
          @click="onSubmit()"
          :loading="isSaving"
          :disabled="isSaving"
        >
          Salvar
        </b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import CitiesService from '@/services/cities.service';
import CepService from '@/services/cep.service';
import PatientsService from '@/services/patients.service';
import AppAutocomplete from '@/components/inputs/Autocomplete.vue';

import flattenDeep from 'lodash/flattenDeep';
import MASKS from '@/constants/masks.constant';
import * as moment from 'moment';

export default {
  components: {
    AppAutocomplete,
  },
  data: () => ({
    MASKS: MASKS,
    patientId: null,
    genders: [
      { char: 'm', name: 'Masculino' },
      { char: 'f', name: 'Feminino' },
    ],
    isLoading: false,
    isSaving: false,
    patient: {
      birthday: null,
    },
    errors: {},
  }),
  methods: {
    citiesGetter: CitiesService.search,
    citySetter: CitiesService.getId,
    onSubmit() {
      this.isSaving = true;
      this.errors = {};

      const { cpf, cep, birthday } = this.patient;
      const data = {
        ...this.patient,
        cpf: cpf.replace(/\D/g, ''),
        cep: (cep || '').replace(/\D/g, ''),
        birthday: moment(birthday?.split('/').reverse().join('-')).toISOString(),
      };

      const promise = this.patientId
        ? PatientsService.update(this.patientId, data)
        : PatientsService.store(data);

      promise
        .then(() => {
          this.$buefy.snackbar.open('Cliente salvo com sucesso.');
          this.$emit('close');
        })
        .catch(({ response }) => {
          this.isSaving = false;
          const { status, data } = response;

          if (status === 422) this.errors = flattenDeep(data);
          else
            this.$buefy.snackbar.open(
              data.message || 'Cliente salvo com sucesso.'
            );
        });
    },
    getCepInfo(cep) {
      if (!cep) return;

      cep = cep.replace(/\D/g, '');
      if (cep.length < 8) return;

      CepService.getCepInfo(cep).then(({ data }) => {
        this.patient = {
          ...this.patient,
          street: data.logradouro,
          neighborhood: data.bairro,
        };

        this.$refs.citySelector.loadResourceById(data.city_id);
      });
    },
  },
};
</script>
